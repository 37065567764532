/* global Mibew */
/* Lauch onlinechat (Mibew) once all is loaded */

function collectOptions() {
    const html =  document.getElementsByTagName('html')[0]
    const options = {
        'lang': html.lang === 'cs' ? 'cs' : 'en',
    }

    // Get params from element META
    for (const node of document.querySelectorAll('meta[name^="cznic:chat:"]')) {
        // Available parameters:
        //     style: cznic
        //     buttonStyle: nichome
        //     domain: //onlinechat.nic.cz  (//onlinechat2.nic.cz is old version 3.1.3)
        //     id: 59e4cd8c98eefc65  (59d23696bdddfc84 on test)
        options[node.name.split(':').pop()] = node.content
    }

    // set mibew options
    options.url = options.domain + '/index.php/chat?locale=' + options.lang + '&style=' + options.style
    options.styleLoader = options.domain + '/index.php/chat/style/popup/' + options.style
    return options
}

function updateMibew() {
    // This function overwrites the code from
    // https://github.com/Mibew/mibew/blob/v3.5.4/src/mibew/js/source/chat_popup.js#L428
    // The difference is only that code of block `if (this.cookiesBlocked) {}` is disabled.
    Mibew.ChatPopup.IFrame.prototype.open = function(url) {
        if (this.isOpened) {
            // Do not open the popup twice.
            return
        }

        // if (this.cookiesBlocked) {
        //     // Last resort. Replace this iframe-based popup with window-based popup
        //     // and try to open a chat in a separate window.
        //     Mibew.Objects.ChatPopups[this.id] = new Mibew.ChatPopup.Window(this.options)
        //     Mibew.Objects.ChatPopups[this.id].open(url)
        //     return
        // }

        if (!this.wrapperDiv) {
            // Create new iframe and its wrapper.
            // There is a bug in IE <= 7 that make "name" attribute unchangeble
            // for elements that already exist. Thus a temporary div is used
            // here as a workaround.
            this.wrapperDiv = document.createElement('div')
            this.wrapperDiv.className = 'mibew-chat-wrapper'
            this.wrapperDiv.setAttribute('id', 'mibew-chat-wrapper-' + this.id)
            this.wrapperDiv.style.display = 'none'
            this.wrapperDiv.innerHTML = '<iframe name="mibewChat' + this.id + '"></iframe>'

            this.iframe = this.wrapperDiv.getElementsByTagName('iframe')[0]
            this.iframe.setAttribute('id', 'mibew-chat-frame-' + this.id)
            this.iframe.className = 'mibew-chat-frame'
            this.iframe.setAttribute('frameBorder', 0)

            document.getElementsByTagName('body')[0].appendChild(this.wrapperDiv)

            // Setup toggle element. As it's not a part of the iframe, it should be
            // treated separately.
            this.toggleDiv = document.createElement('div')
            this.toggleDiv.setAttribute('id', 'mibew-chat-frame-toggle-' + this.id)
            this.toggleDiv.className = 'mibew-chat-frame-toggle mibew-chat-frame-toggle-on'
            Mibew.Utils.addEventListener(this.toggleDiv, 'click', function() {
                const popups = Mibew.Objects.ChatPopups,
                    matches = /^mibew-chat-frame-toggle-([0-9A-Za-z]+)$/.exec(this.id)

                if (matches && popups[matches[1]]) {
                    popups[matches[1]].toggle()
                }
            })
            document.getElementsByTagName('body')[0].appendChild(this.toggleDiv)
        }

        this.wrapperDiv.style.display = 'block'
        this.toggleDiv.style.display = 'block'
        this.iframe.src = url || this.buildChatUrl()
        this.isOpened = true

        // Store style name in case we need it during iframe reopening
        Mibew.Utils.createCookie('mibew-chat-frame-style-' + this.id, this.styleName)

        // Check minification status of the popup and toggle it if needed.
        const minifiedPopup = Mibew.Utils.readCookie('mibew-chat-frame-minified-' + this.id)
        if (minifiedPopup === 'true') {
            this.toggle()
        }
    }
}

function initMibewChatAndCreateButton(options) {
    Mibew.ChatPopup.init({
        'id': options.id,
        'url': options.url,
        'preferIFrame': true,
        'modSecurity': false,
        'height': 480,
        'width': 640,
        'resizable': true,
        'style': options.style,
        'styleLoader': options.styleLoader,
    })

    /* create button and appen it to the onlinechat span */
    const button = document.createElement('a')
    button.setAttribute('id', 'mibew-agent-button')
    button.href = 'javascript:(function(){})()'
    button.setAttribute('onclick', 'Mibew.Objects.ChatPopups["' + options.id + '"].open();')

    const imageButton = document.createElement('img')
    imageButton.src = options.domain + '/index.php/b?i=' + options.buttonStyle + '&lang=cs' // image only exists in cs

    // if there is no image we replace by a generic
    imageButton.onerror = function() {
        imageButton.src = 'https://www.nic.cz/static/www.nic.cz/images/status-new-icon-red.png'
    }
    button.appendChild(imageButton)

    const textButton = document.createElement('span')
    textButton.innerHTML = document.getElementById('onlinechat').getAttribute('data-text')
    button.appendChild(textButton)

    document.getElementById('onlinechat').appendChild(button)
}

// set options after all is loaded
window.addEventListener('load', function() {
    updateMibew()
    initMibewChatAndCreateButton(collectOptions())
})
